export default {
  'default': {
    'territorial': [
      'country',
      'biome',
      'province',
      'canton',
      'parish',
      'geographic_region',
    ],
    'land': [
      'protected_natural_area',
      'sub_national_protected_natural_area',
      'ramsar_wetland',
      'biosphere_reserve',
      'protected_forest',
      'indigenous_territory',
      'protection_zone',
      'socio_bosque_program',
      'hidrological_protection_area',
      'connectivity_corridor',
      'biogeographic_unit',
      'marine_reserve',
      'conservation_and_sustainable_use_area',
      'water_recharge_zona',
      'state_forest_patrimony',
      'river_basin_district',
      'ecosystem',
      'watershed_otto_1',
      'watershed_otto_2',
      'watershed_otto_3',
      'watershed_otto_4',
    ]
  },
  'simplified': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
    ]
  },
  'infrastructure': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
    ]
  },
  'fire': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
      'special_territories',
      'biosphere_reserve',
      'geoparks',
    ],
    'land': [
      'settlement',
      'quilombo',
      'indigenous_land_new',
      'federal_conservation_units_integral_protection',
      'federal_conservation_units_sustainable_use',
      'state_conservation_units_integral_protection',
      'state_conservation_units_sustainable_use',
    ]
  },
  'soil': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
    ]
  },
  'sentinel': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
      'special_territories',
    ],
    'land': [
      'settlement',
      'quilombo',
      'indigenous_land_new',
      'federal_conservation_units_integral_protection',
      'federal_conservation_units_sustainable_use',
      'state_conservation_units_integral_protection',
      'state_conservation_units_sustainable_use',
    ]
  }
};
